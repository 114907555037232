<template>
  <div class="proBar">
    <el-progress :percentage="50"></el-progress>
    <div class="row progress-describe">
      <div class="col-lg-4 col-md-4 col-xs-4">
        0
      </div>
      <div class="col-lg-4 col-md-4 col-xs-4 color-green text-center">
        58,000
      </div>
      <div class="col-lg-4 col-md-4 col-xs-4 text-right">
        100,000
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.proBar {
  /deep/.el-progress-bar {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }
  /deep/.el-progress-bar__outer {
    height: 10px !important;
  }
  /deep/.el-progress-bar__inner {
    border-radius: 0px !important;
  }
  /deep/.el-progress__text {
    display: none !important;
  }
  .color-green {
    color: #3f8278;
  }
  .progress-describe {
    font-family: Louis George Cafe;
    font-size: 14px;
    line-height: 16px;
    color: #4f4f4f;
    margin-top: 8px;
  }
}
@media (max-width: 767px) {
}
</style>
