<template>
  <div id="balances">
    <broadPage>
      <div slot="content">
        <div class="content">
          <div class="b-account">
            <div v-show="address">
              {{
                address.replace(/\s+/g, "").substr(0, 6) +
                  "..." +
                  address.substr(38, 4)
              }}
            </div>

            <img src="./images/mask.svg" v-show="accountType === 'matemask'"/>
            <img src="./images/coinbase.svg" v-show="accountType === 'coinbase'"/>
            <img src="./images/connect.svg" v-show="accountType === 'wallet'"/>
          </div>
          <div class="b-border">
            <div class="b-balances">
              
              <!-- <p class="balances-title">
                Balances
                <el-tooltip class="item" effect="dark" content="Top Left 提示文字" placement="top">
                  <i class="fa fa-question-circle"></i>
                </el-tooltip>
              </p> -->
              <div class="balances-contnet">
                <div class="balances-item">
                  <el-row>
                    <el-col :span="3">
                      <img src="./images/balances.svg" />
                    </el-col>
                    <el-col :span="3"><p class="item-2">ROC</p></el-col>
                    <el-col :span="11" class="text-right">
                      <div class="item-3">{{rocMount}}</div>
                      <div class="item-4">
                        <span>≈</span> {{roc_rousd}} roUSD
                      </div>
                    </el-col>
                    <el-col :span="7" class="text-right">
                      <!-- <router-link to="/getRoc" class="btn-small btn-primary"
                        >Trade</router-link
                      > -->
                    </el-col>
                  </el-row>
                </div>
                <div class="balances-item"  v-if="balanceType === 'roc'">
                  <el-row>
                    <el-col :span="3">
                      <img src="./images/balances.svg" />
                    </el-col>
                    <el-col :span="3"><p class="item-2">roUSD</p></el-col>
                    <el-col :span="11" class="text-right">
                      <div class="item-3">{{rousdMount}}</div>
                      <div class="item-4">
                        <span>≈</span> {{rousdMount}} USD
                      </div>
                    </el-col>
                    <el-col :span="7" class="text-right">
                      <router-link to="/sellRoc" class="btn-small btn-primary"
                        >Trade</router-link
                      >
                    </el-col>
                  </el-row>
                </div>

                <div class="balances-item"  v-if="balanceType === 'do'">
                  <el-row>
                    <el-col :span="3">
                      <img src="./images/do.svg" />
                    </el-col>
                    <el-col :span="3"><p class="item-2">DO</p></el-col>
                    <el-col :span="11" class="text-right">
                      <div class="item-3">{{doMount}}</div>
                      <div class="item-4">
                        <span>≈</span> {{doMount}} USD
                      </div>
                    </el-col>
                    <el-col :span="7" class="text-right">
                      <router-link to="/getDO" class="btn-small btn-primary"
                        >Trade</router-link
                      >
                    </el-col>
                  </el-row>
                </div>
              </div>

              <div class="mt-4" v-if="balanceType === 'do'">
                <!-- https://etherscan.io/address/0xcec0ac18d0f4a8d6cdc93f4920a1d850abe4fa63
                <router-link to="/roc" class="btn-small btn-outline-primary">View on Explorer</router-link> -->
                <a :href="`https://etherscan.io/token/${this.address}`" target='_blank' class="btn-small btn-outline-primary view-explorer">View on Explorer</a>
              </div>
            </div>
          </div>



          <div class="b-Issuance" v-if="balanceType === 'roc'">
            <p class="Issuance-title">ROC in Issuance</p>
            <div class="Issuance-content">
              <div class="Iss-item1">
                <p>Currently on Group 2/8, Layer 20/100</p>
                <proBar></proBar>
              </div>
              <div class="Iss-item2">
                <el-row type="flex" justify="space-between">
                  <el-col>
                    <p>ROC Issued (Group 2)</p>
                    <p>1,958,000</p>
                  </el-col>
                   <el-col>
                    <p>ROC Left (Group 2)</p>
                    <p>8,042,000</p>
                  </el-col>
                </el-row>
                <el-row class="mt-4">
                  <el-col>
                    <p>ROC Issued (Group 2)</p>
                    <p>1,958,000</p>
                  </el-col>
                </el-row>
                <div class="mt-4">
                   <router-link to="/roc" class="btn-small btn-outline-primary">View on Explorer</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </broadPage>
  </div>
</template>

<script>
import broadPage from "../../components/broadPage.vue";
import ProBar from "../../components/proBar.vue";
import { getDisplayBalance, ROCContract, rousdBlance, rocBlance, RocPrice, BigNum, doBlance} from "../../unitls"
import BigNumber from 'bignumber.js'
export default {
  name: "balances",
  data() {
    return {
      address: localStorage.getItem('account'),
      accountType: localStorage.getItem('loginType'),
      rocMount: 0,
      rousdMount: 0,
      doMount: 0,
      ToRoc: 0, // 查看这个人的rousd能兑换多少roc,
      balanceType: '',
      roc_rousd: 0
    };
  },
  components: { broadPage, ProBar },
  methods: {
    BigNum (val) {
      return new BigNumber(val)
    }
  },
  beforeMount () {
    // alert(this.$route.query.type)
    this.balanceType = this.$route.query.type
    // console.log(this.$route.query.type, 'xxx')
  },
  mounted () {


    
    if (this.address) {
      
      // 获取rousdc的资产
      rousdBlance(this.address).then(val => {
        this.rousdMount = val
        // 查看本人的rousd能兑换多少roc
        // RousdToRoc(this.BigNum(val)).then(rocObj => {
        //   this.ToRoc = rocObj[0]
        // })
      })
      // 获取roc的资产
      rocBlance(this.address).then(val => {
        this.rocMount = val
        // 获取roc的平均发行价格；
        RocPrice().then(data => {
          this.roc_rousd = getDisplayBalance(BigNum(data))
          this.roc_rousd = (this.roc_rousd * val).toFixed(6)
        })
      })
      // 获取do的资产
      doBlance(this.address).then(val => {
        console.log(val, 'do-mount')
        this.doMount = val
      })
      // 获取ROC的平均发行价格
      // doBlance(this.address).then(val => {
      //   console.log(val, 'do-mount')
      //   this.doMount = val
      // })
    }
  }
};
</script>
<style lang="less" scoped>
#balances {
  margin-top:140px;
  min-height: 600px;
  .content {
    padding: 30px 0px;
    .view-explorer {
      color: #085DA3;
      cursor: pointer;
    }
    .b-account {
      border: 1px solid #2f98ee;
      padding: 10px 18px;
      display: flex;
      justify-content: space-between;
      margin: 0px 30px 30px;
    }
    .b-border {
      border-top: 1px solid #e0e0e0;
      .b-balances {
        padding: 20px 30px 30px;
        .balances-title {
          font-size: 16px;
          color: #4f4f4f;
          font-weight: normal;
        }
      }
      .balances-contnet {
        margin-top: 20px;
        .balances-item {
          border: 1px solid #e0e0e0;
          padding: 16px;
          .item-2 {
            font-family: Louis George Cafe;
            font-size: 20px;
            line-height: 26px;
            color: #085da3;
          }
          .item-3 {
            font-family: Louis George Cafe;
            font-size: 16px;
            line-height: 140%;
            color: #4f4f4f;
          }
          .item-4 {
            font-family: Louis George Cafe;
            font-size: 12px;
            line-height: 140%;
            color: #4f4f4f;
          }
        }

        .balances-item:first-child {
          border-bottom: 0px;
        }
      }
    }
    .b-Issuance {
      padding: 20px 30px 30px;
      border-top: 1px solid #e0e0e0;

      .Issuance-title {
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 19px;
        color: #4f4f4f;
        margin-bottom: 20px;
      }
      .Issuance-content {
        border: 1px solid #e0e0e0;
        .Iss-item1 {
          padding: 20px;
          border-bottom: 1px solid #e0e0e0;
          p {
            font-family: Louis George Cafe;
            font-size: 14px;
            color: #085da3;
            margin-bottom: 15px;
          }
        }

        .Iss-item2 {
          padding: 20px 20px 30px;
          p:first-child {
            font-family: Louis George Cafe;
            font-size: 14px;
            color: #085da3;
          }
          p:last-child {
            font-family: Louis George Cafe;
            font-size: 14px;
            color: #4f4f4f;
          }
        }
      }
    }
  }
}
</style>
